import React, { useEffect, useMemo, useRef, useState } from 'react';
import { PageContext } from 'shared/lib/toolkit/store/pagecontext';
import { Box, Modal, Typography } from '@mui/material';
import {
  useLazyGetUsersQuery,
  useLazyGetChatsListQuery,
  useGetOrganizationByUserQuery,
  useLazyGetLeadsListQuery,
  useLazyGetTendersListQuery,
  useGetUnReadMessagesQuery,
  useGetLeadStatusQuery,
  useLazyGetCatalogQuery,
  useLazyGetFormTemplatesQuery,
} from 'slices/ChatSlice';
import Icons from 'shared/Icons/Chats';

import WebApi from 'services/index';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSession } from 'next-auth/react';
import theme from 'shared/theme';
import Layout from './Layout';
import useNotificationSound from 'shared/hooks/useNotificationSound';

interface Props {
  id?: number;
  type?: string;
  chat?: string;
  organizationId?: string | string[] | null;
  background?: string;
}

export const UI = ({
  id,
  type = 'outgoing',
  chat = 'advert',
  organizationId = null,
  background,
}: Props) => {
  const playNotificationSound = useNotificationSound('/sound/notification.mp3');
  const { data: sessionData } = useSession();
  const userId = sessionData?.user?.id;
  const [open, setOpen] = useState<boolean>(false);
  const [chatType, setChatType] = useState(chat);
  const [filter, setFilter] = useState(type);
  const [leadId, setLeadId] = useState<number | null>(null);
  const [tenderId, setTenderId] = useState<number | null>(null);
  const [lead, setLead] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [advertOwnerLead, setAdvertOwnerLead] = useState<any>([]);
  const [advertLeadOwner, setAdvertLeadOwner] = useState<any>([]);
  const [advertCustomer, setAdvertCustomer] = useState<any>([]);
  const [tenderOwnerLead, setTenderOwnerLead] = useState<any>([]);
  const [tenderLeadOwner, setTenderLeadOwner] = useState<any>([]);
  const [tenderCustomer, setTenderCustomer] = useState<any>([]);
  const [userMessages, setUserMessages] = useState<any>([]);
  const [anketsMessages, setAnketsMessages] = useState<any>([]);
  const [status, setStatus] = useState<number | string>(0);

  const protocols = encodeURIComponent(
    JSON.stringify({ authorization: sessionData?.user?.accessToken })
  );

  const { sendJsonMessage, lastJsonMessage, readyState }: any = useWebSocket(
    `${process.env.NEXT_PUBLIC_CHAT}`,
    { protocols, shouldReconnect: () => true }
  );

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      sendJsonMessage({
        event: 'subscribe',
        data: {
          channel: 'general-chatroom',
        },
      });
    }
  }, [readyState]);

  const { data: company } = useGetOrganizationByUserQuery({
    userId: userId,
    organizationStatus: 'verified',
  });

  const { data: unReadMessages, refetch } = useGetUnReadMessagesQuery({
    count: true,
    countLead: true,
    countAdvert: true,
    countTender: true,
    countRelation: true,
    countOrganization: true,
  });

  const { data: statuses } = useGetLeadStatusQuery({});

  const [getTender, tender] = useLazyGetTendersListQuery();
  const [getForm, form] = useLazyGetFormTemplatesQuery();
  const [setChats, chats] = useLazyGetChatsListQuery();
  const [getUsers, users] = useLazyGetUsersQuery();
  const [getLeads, leads] = useLazyGetLeadsListQuery();
  const [getCatalog, catalog] = useLazyGetCatalogQuery();

  const lastMessage = useMemo(() => {
    if (lastJsonMessage?.event === 'chat-user-message-added') {
      playNotificationSound();
    }
    if (lastJsonMessage?.event === 'message-added') {
      const advertOwnerLeadSocket = chats.data?.advertOwnerLead?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data.advertOwnerLead.id
          )
        : null;
      const advertLeadOwnerSocket = chats.data?.advertLeadOwner?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data.advertLeadOwner.id
          )
        : null;
      const advertCustomerSocket = chats.data?.advertChat?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data?.advertChat.id
          )
        : null;
      const tenderOwnerLeadSoket = chats.data?.tenderOwnerLead?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data?.tenderOwnerLead.id
          )
        : null;
      const tenderLeadOwnerSocket = chats.data?.tenderLeadOwner?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data?.tenderLeadOwner.id
          )
        : null;
      const tenderCustomerSocket = chats.data?.tenderChat?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data?.tenderChat.id
          )
        : null;
      const userChat = chats.data?.userChat?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data.userChat.id
          )
        : null;
      const anketsChatSocket = chats.data?.userChat?.id
        ? lastJsonMessage.data.messages.find(
            (item: any) => item.chatId === chats.data.catalogChat.id
          )
        : null;
      if (advertOwnerLeadSocket)
        setAdvertOwnerLead([...advertOwnerLead, advertOwnerLeadSocket]);
      if (advertLeadOwnerSocket)
        setAdvertLeadOwner([...advertLeadOwner, advertLeadOwnerSocket]);
      if (advertCustomerSocket)
        setAdvertCustomer([...advertCustomer, advertCustomerSocket]);
      if (tenderOwnerLeadSoket)
        setTenderOwnerLead([...tenderOwnerLead, tenderOwnerLeadSoket]);
      if (tenderLeadOwnerSocket)
        setTenderLeadOwner([...tenderLeadOwner, tenderLeadOwnerSocket]);
      if (tenderCustomerSocket)
        setTenderCustomer([...tenderCustomer, tenderCustomerSocket]);
      if (userChat) setUserMessages([...userMessages, userChat]);
      if (anketsChatSocket)
        setAnketsMessages([...anketsMessages, anketsChatSocket]);
    }
    if (
      lastJsonMessage?.event === 'chat-user-message-added' ||
      lastJsonMessage?.event === 'chat-user-message-deleted'
    ) {
      refetch();
    } else {
      return lastJsonMessage;
    }
  }, [lastJsonMessage]);

  const onHandleSelectTender = async (id: number) => {
    const organizationIds = company?.map((item: any) => item.id);
    getLeads({
      filters: [filter, chatType].map((item) => item),
      ...(organizationId && { organizationId }),
      ...(!organizationId && {
        organizationIds: organizationIds,
      }),
      tenderId: id,
      ...(status && { leadStatus: status }),
    });
    setTenderId(id);
  };

  const onHandleClose = () => {
    setOpen(false);
    setLeadId(null);
    setAdvertOwnerLead([]);
    setAdvertLeadOwner([]);
    setAdvertCustomer([]);
    setTenderOwnerLead([]);
    setTenderLeadOwner([]);
    setTenderCustomer([]);
  };

  const onHandleChangeFilter = (type: string) => {
    setLeadId(null);
    setLead(null);
    setAdvertOwnerLead([]);
    setAdvertLeadOwner([]);
    setAdvertCustomer([]);
    setTenderOwnerLead([]);
    setTenderLeadOwner([]);
    setTenderCustomer([]);
    setFilter(type);
  };

  const onHandleChangeChatType = (type: string) => {
    if (type === 'tender') {
      setFilter('incoming');
    }
    setLeadId(null);
    setLead(null);
    setAdvertOwnerLead([]);
    setAdvertLeadOwner([]);
    setAdvertCustomer([]);
    setTenderOwnerLead([]);
    setTenderLeadOwner([]);
    setTenderCustomer([]);
    setChatType(type);
  };

  const onHandleSelectChat = async (id: number) => {
    const data = await WebApi().Leads.getList({ id });

    setChats({
      leadId: data?.id,
      advertId: data?.advert?.id,
      organizationId: id,
    });

    if (chatType === 'advert') {
      if (filter === 'outgoing') {
        getUsers({ organizationId: data?.organizationId });
      } else if (filter === 'incoming') {
        getUsers({ organizationId: data.advert?.organizationId });
      }
    } else if (chatType === 'tender') {
      if (filter === 'outgoing' && data) {
        getUsers({ organizationId: data.organizationId });
      } else if (filter === 'incoming') {
        getUsers({
          organizationId: organizationId ?? data.tender?.organizationId,
        });
      }
    } else if (chatType === 'company') {
      getUsers({ organizationId: id });
    }
    setAdvertOwnerLead([]);
    setAdvertLeadOwner([]);
    setAdvertCustomer([]);
    setTenderOwnerLead([]);
    setTenderLeadOwner([]);
    setTenderCustomer([]);
    setLeadId(id);
    setLead(data);
    setLoading(false);
  };

  const onHandleSelectAnkets = async (catalogId: number) => {
    const id = catalogId;
    setLoading(true);
    const org = catalog.data.find((item: any) => (catalogId = item.id));

    setChats({
      catalogId: id,
      organizationId: org.organizationId,
    });
    getUsers({ organizationId: org.organizationId });
    setAdvertOwnerLead([]);
    setAdvertLeadOwner([]);
    setAdvertCustomer([]);
    setTenderOwnerLead([]);
    setTenderLeadOwner([]);
    setTenderCustomer([]);
    setLeadId(id);
    setLoading(false);
  };

  useEffect(() => {
    if (tender?.data?.length) {
      const organizationIds = company?.map((item: any) => item.id);
      setTenderId(tender.data[0].id);
      if (filter === 'incoming') {
        getLeads({
          filters: [filter, chatType].map((item) => item),
          ...(organizationId && { organizationId }),
          ...(!organizationId && {
            organizationIds: organizationIds,
          }),
          tenderId: tender.data[0].id,
          ...(status && { leadStatus: status }),
        });
      } else if (filter === 'outgoing') {
        getLeads({
          filters: [filter, chatType].map((item) => item),
          ...(organizationId && { organizationId }),
          ...(!organizationId && {
            organizationIds: organizationIds,
          }),
          ...(status && { leadStatus: status }),
        });
      }
    }
  }, [tender]);

  useEffect(() => {
    if (leads?.data?.length) {
      setLoading(true);
      setAdvertOwnerLead([]);
      setAdvertLeadOwner([]);
      setAdvertCustomer([]);
      setTenderOwnerLead([]);
      setTenderLeadOwner([]);
      setTenderCustomer([]);
      setLead(null);
      setChats({});
      onHandleSelectChat(leads.data[0].id);
    } else {
      setLoading(false);
    }
  }, [leads]);

  useEffect(() => {
    if (catalog?.data?.length) {
      setAdvertOwnerLead([]);
      setAdvertLeadOwner([]);
      setAdvertCustomer([]);
      setTenderOwnerLead([]);
      setTenderLeadOwner([]);
      setTenderCustomer([]);
      setLead(null);
      setChats({
        catalogId: catalog.data[0].id,
        organizationId: catalog.data[0].organizationId,
      });
    }
  }, [catalog]);

  useEffect(() => {
    setLoading(true);
    if (chatType === 'company' && company) onHandleSelectChat(company[0]?.id);
    if (chatType === 'advert' && company) {
      const organizationIds = company?.map((item: any) => item.id);
      getLeads({
        filters: [filter, chatType].map((item) => item),
        ...(organizationId && { organizationId }),
        ...(!organizationId && {
          organizationIds: organizationIds,
        }),
        ...(status && { leadStatus: status }),
      });
    }
    if (chatType === 'tender' && company) {
      const organizationIds = company?.map((item: any) => item.id);
      getTender({ organizationIds, ...(status && { leadStatus: status }) });
      getLeads({
        filters: [filter, chatType].map((item) => item),
        ...(organizationId && { organizationId }),
        ...(!organizationId && {
          organizationIds: organizationIds,
        }),
        ...(status && { leadStatus: status }),
      });
    }
    if (chatType === 'ankets') {
      const organizationIds = company?.map((item: any) => item.id);
      getCatalog({ organizationIds });
      setLoading(false);
    }
  }, [chatType, company, filter, status]);

  useEffect(() => {
    if (chats.data?.catalogChat) {
      getForm({
        id: chats.data.catalogChat.formTemplateId,
        formTemplateProp: true,
      });
    }
  }, [chats.data]);

  return (
    <Box>
      <PageContext.Provider
        value={{
          lastJsonMessage,
          leads: leads.data,
          tenders: tender.data,
          catalog: catalog.data,
          lead,
          form,
          chats,
          company,
          statuses,
          users,
          chatType,
          onHandleChangeChatType,
          onHandleSelectTender,
          organizationId,
          advertOwnerLead,
          advertCustomer,
          advertLeadOwner,
          tenderOwnerLead,
          tenderCustomer,
          tenderLeadOwner,
          userMessages,
          anketsMessages,
          leadId,
          tenderId,
          filter,
          unReadMessages,
          isLoading:
            (chats.status !== 'fulfilled' &&
              chats.status !== 'uninitialized') ||
            (leads.status !== 'fulfilled' &&
              leads.status !== 'uninitialized') ||
            loading,
          isFetching: chats.isFetching,
          onHandleChangeFilter,
          onHandleSelectChat,
          onHandleSelectAnkets,
          setChats,
          setLead,
          status,
          setStatus,
          onHandleClose,
          lastMessage,
        }}
      >
        <Modal onClose={() => setOpen(false)} open={open}>
          <Box>
            <Layout />
          </Box>
        </Modal>
        <Box
          sx={{
            color: theme.palette.grey[900],
            background: background,
            position: 'relative',
            cursor: 'pointer',
            width: '100%',
            height: '30.75px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            gap: 1,
            [theme.breakpoints.down('lg')]: {
              pr: 0,
            },
            ':hover': { opacity: 0.7 },
          }}
          onClick={() => {
            setOpen(true);
            setLeadId(id ?? null);
            id ? onHandleSelectChat(id) : null;
          }}
        >
          {unReadMessages?.total > 0 ? (
            <Box
              sx={{
                position: 'absolute',
                left: 12,
                borderRadius: '50%',
                p: 1,
                background: 'red',
                width: 3,
                height: 3,
                top: -6,
                color: '#fff',
                fontSize: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {unReadMessages?.total}
            </Box>
          ) : null}
          <Box sx={{ display: 'flex' }}>
            <Icons />
          </Box>
          <Box
            sx={{
              display: 'none',
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 16,
                fontFamily: 'Rubik',
                fontWeight: 600,
                color: theme.palette.secondary.light,
              }}
            >
              Чаты
            </Typography>
          </Box>
        </Box>
      </PageContext.Provider>
    </Box>
  );
};
