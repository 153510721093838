import { useSession } from 'next-auth/react';
import { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Company from 'services/Companies';
import WebApi from 'services/index';
import { useLazyGetDepartmentQuery } from 'slices/ChatSlice';
import { PageContext } from 'store/pagecontext';

const useModel = (organizationId: number, chatUsers: any, chatId: number) => {
  const [open, setOpen] = useState<boolean>(false);
  const { data: sessionData } = useSession();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedEmployees(initialSelectedEmployees);
    setOpen(false);
  };
  const initialSelectedEmployees = chatUsers.reduce((acc: any, user: any) => {
    acc[user.userId] = true;
    return acc;
  }, {});
  const [selectedDepartments, setSelectedDepartments] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedEmployees, setSelectedEmployees] = useState<{
    [key: string]: boolean;
  }>(initialSelectedEmployees);

  const [expandedDepartments, setExpandedDepartments] = useState<{
    [key: string]: boolean;
  }>({});

  const [getDepartment, { data: departments }] = useLazyGetDepartmentQuery();

  const { lead, chats, filter, company, catalog } = useContext<{
    lead?: {
      organizationId?: string;
      tender: { organizationId: string };
      advert: { organizationId: string };
    };
    chats: any;
    filter: string;
    company: Company;
    catalog: any;
  }>(PageContext);

  useEffect(() => {
    getDepartment({
      organizationId: lead
        ? filter === 'incoming'
          ? lead?.tender?.organizationId ?? lead?.advert?.organizationId
          : lead.organizationId
        : chats?.data?.userChat?.organizationId ??
          chats?.data?.catalogChat?.formTemplate.organization.id,
      tree: false,
    });
  }, [lead?.organizationId, getDepartment]);

  useEffect(() => {
    if (departments) {
      const existingUserIds = new Set(
        chatUsers.map((user: any) => user.userId)
      );
      const newSelectedEmployees: any = {};
      const newSelectedDepartments: any = {};

      departments.forEach((department: any) => {
        const departmentEmployees = department.employees;
        let allSelected = true;

        departmentEmployees?.forEach((employee: any) => {
          const isSelected = existingUserIds.has(employee.userId);
          newSelectedEmployees[employee.userId] = isSelected;
          allSelected = allSelected && isSelected;
        });

        newSelectedDepartments[department.id] = allSelected;
      });

      setSelectedEmployees(newSelectedEmployees);
      setSelectedDepartments(newSelectedDepartments);
    }
  }, [departments, chatUsers]);

  const handleDepartmentChange = (departmentId: string, isChecked: boolean) => {
    setSelectedDepartments((prev) => ({ ...prev, [departmentId]: isChecked }));

    const employees = departments?.find((dept: any) => dept.id === departmentId)
      ?.employees;

    if (employees) {
      const updatedEmployees = employees.reduce((acc: any, cur: any) => {
        acc[cur.userId] = isChecked;
        return acc;
      }, {});
      setSelectedEmployees((prev) => ({ ...prev, ...updatedEmployees }));
    }
  };

  const handleEmployeeChange = async (
    departmentId: string,
    employeeId: string,
    isChecked: boolean
  ) => {
    setSelectedEmployees((prev) => ({ ...prev, [employeeId]: isChecked }));
    departments.forEach(async (dept: any) => {
      if (dept.employees?.some((emp: any) => emp.userId === employeeId)) {
        const isAnyEmployeeSelected = dept.employees.some((emp: any) => {
          if (emp.userId === employeeId) {
            return isChecked;
          }
          return selectedEmployees[emp.userId];
        });
        setSelectedDepartments((prevDepartments) => ({
          ...prevDepartments,
          [dept.id]: isAnyEmployeeSelected,
        }));
      }
    });
  };

  const handleToggleDepartment = (departmentId: string) => {
    setExpandedDepartments((prev) => ({
      ...prev,
      [departmentId]: !prev[departmentId],
    }));
  };
  const onSubmit = async () => {
    const userIds = Object.entries(selectedEmployees)
      .filter(([userId, isSelected]) => isSelected)
      .map(([userId]) => ({ chatId: chatId, userId: +userId }));

    const { err, data } = await WebApi().Chat.updateChatsUsers(userIds);
    if (!err) toast.success('Пользователь добавлен в чат');
    else toast.error('Что-то пошло не так');

    setOpen(false);
  };

  return {
    departments: departments ?? [],
    open,
    handleOpen,
    handleClose,
    selectedEmployees,
    selectedDepartments,
    expandedDepartments,
    handleToggleDepartment,
    handleDepartmentChange,
    handleEmployeeChange,
    onSubmit,
  };
};
export default useModel;
