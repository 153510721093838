import { useContext, useEffect, useMemo, useState } from 'react';
import { PageContext } from 'store/pagecontext';
import { Backdrop, Box, Button, CircularProgress } from '@mui/material';
import Leads from '../Leads';
import Filters from '../Filters';
import theme from 'shared/theme';
import AdvertDescription from '../Description/Advert';
import Chat from '../Chat';
import ChatType from '../ChatTypes';
import Company from '../Company';
import Empty from '../Empty';
import { Partners } from '../Partners';
import { Tenders } from '../Tenders';
import TenderDescription from '../Description/Tender';
import { Close } from '@mui/icons-material';
import Catalogs from '../Catalogs';
import Ankets from '../Ankets';

export const UI = () => {
  const {
    lead,
    leads,
    filter,
    onHandleClose,
    company,
    chats,
    chatType,
    isLoading,
    advertOwnerLead,
    advertCustomer,
    advertLeadOwner,
    tenderOwnerLead,
    tenderCustomer,
    tendertLeadOwner,
    userMessages,
    anketsMessages,
  }: {
    lead: any;
    leads: any;
    filter: any;
    onHandleClose: Function;
    company: any;
    chats: any;
    chatType: any;
    isLoading: boolean;
    advertOwnerLead: any;
    advertCustomer: any;
    advertLeadOwner: any;
    tenderOwnerLead: any;
    tenderCustomer: any;
    tendertLeadOwner: any;
    userMessages: any;
    anketsMessages: any;
  } = useContext(PageContext);
  const [resize, setResize] = useState(false);
  const [type, setType] = useState('partner');

  const onHandleResize = () => {
    setResize(!resize);
  };
  useEffect(() => {
    if (chatType === 'tender') {
      setType('advert');
    }
    if (chatType === 'advert') {
      setType('partner');
    }
  }, [chatType]);
  useMemo(() => {
    if (chatType === 'company') {
      setResize(false);
    }
  }, [chatType]);
  useMemo(() => {
    if (chatType === 'tender' && filter === 'outgoing') {
      setType('partner');
    }
  }, [filter]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          height: '100vh',
          background: '#fff',
          zIndex: 999999999,
        }}
      >
        <Box sx={{ width: '100px' }}>
          <ChatType />
        </Box>
        <Box
          sx={{
            border: '1px solid black',
            borderRadius: 1,
            padding: 1,
            margin: 1,
            width: 'calc(100% / 3 - 100px)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {chatType === 'company' || chatType === 'ankets' ? null : (
            <Button
              variant="contained"
              onClick={onHandleResize}
              sx={{
                background: resize ? theme.palette.grey[500] : 'primary',
                color: resize ? theme.palette.grey[600] : 'primary',
                height: 40,
                mr: 1,
              }}
            >
              Чат с колегами
            </Button>
          )}
          {resize ? (
            <Box>
              {chatType === 'advert' ? (
                <Box>
                  {chats?.data?.advertOwnerLead ||
                  chats?.data?.advertLeadOwner ? (
                    <Chat
                      title=""
                      hide
                      userId={
                        chats?.data?.advertOwnerLead
                          ? lead?.advert?.organization.id
                          : lead?.userId
                      }
                      chat={
                        chats?.data?.advertOwnerLead ??
                        chats?.data?.advertLeadOwner
                      }
                      socketMessages={
                        chats?.data?.advertOwnerLead
                          ? advertOwnerLead
                          : advertLeadOwner
                      }
                    />
                  ) : (
                    <Empty />
                  )}
                </Box>
              ) : null}
              {chatType === 'tender' ? (
                <Box>
                  {chats?.data?.tenderOwnerLead ||
                  chats?.data?.tenderLeadOwner ? (
                    <Chat
                      title=""
                      hide
                      userId={
                        chats?.data?.tenderOwnerLead
                          ? lead?.tender?.organization.id
                          : lead?.userId
                      }
                      chat={
                        chats?.data?.tenderOwnerLead ??
                        chats?.data?.tenderLeadOwner
                      }
                      socketMessages={
                        chats?.data?.tenderOwnerLead
                          ? tenderOwnerLead
                          : tendertLeadOwner
                      }
                    />
                  ) : (
                    <Empty />
                  )}
                </Box>
              ) : null}
            </Box>
          ) : (
            <Box>
              {chatType === 'advert' || chatType === 'tender' ? (
                <Filters />
              ) : null}
              {chatType === 'advert' ? <Leads /> : null}
              {chatType === 'tender' && filter === 'incoming' ? (
                <Tenders />
              ) : null}
              {chatType === 'tender' && filter === 'outgoing' ? (
                <Leads />
              ) : null}
              {chatType === 'company' ? <Company /> : null}
              {chatType === 'ankets' ? <Catalogs /> : null}
            </Box>
          )}
        </Box>
        <Box
          sx={{
            width:
              chatType === 'company'
                ? 'calc(77%  - 100px)'
                : 'calc(100% / 3  + 100px)',
            padding: 1,
          }}
        >
          {(leads?.length &&
            (chatType === 'advert' || chatType === 'tender')) ||
          (company?.length && chatType === 'company') ||
          chatType === 'ankets' ? (
            <Box
              sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
              }}
            >
              {chatType === 'company' ? (
                <Button
                  variant="contained"
                  onClick={() => onHandleClose()}
                  sx={{
                    background: 'primary',
                    color: 'primary',
                    height: 40,
                  }}
                >
                  <Close />
                </Button>
              ) : null}

              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ width: '100%' }}>
                  {chatType === 'tender' ? <TenderDescription /> : null}
                  {((chatType === 'advert' && chats?.data?.advertChat) ||
                    (chatType === 'tender' && chats?.data?.tenderChat)) &&
                  !isLoading ? (
                    <AdvertDescription />
                  ) : null}
                  {chatType === 'advert' && !isLoading ? (
                    chats?.data?.advertChat ? (
                      <Chat
                        title="Чат по Заявке"
                        chat={chats?.data?.advertChat}
                        socketMessages={advertCustomer}
                      />
                    ) : (
                      <Empty />
                    )
                  ) : null}
                  {chatType === 'tender' && !isLoading ? (
                    chats?.data?.tenderChat ? (
                      <Chat
                        title="Чат по Тендеру"
                        chat={chats?.data?.tenderChat}
                        socketMessages={tenderCustomer}
                      />
                    ) : (
                      <Empty />
                    )
                  ) : null}
                  {chatType === 'company' && !isLoading ? (
                    chats?.data?.userChat ? (
                      <Box sx={{ width: '100%' }}>
                        <Chat
                          title="Чат по Компании"
                          chat={chats?.data?.userChat}
                          socketMessages={userMessages}
                        />
                      </Box>
                    ) : (
                      <Empty />
                    )
                  ) : null}
                  {chatType === 'ankets' ? <Ankets /> : null}
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
        {!(chatType === 'company') ? (
          <Box
            sx={{
              width: 'calc(100% / 3 - 100px)',
              border: '1px solid black',
              borderRadius: 1,
              padding: 1,
              margin: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                mb: 2,
              }}
            >
              {chatType === 'tender' ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={() => {
                      setType('partner');
                    }}
                    sx={{
                      color:
                        type === 'partner'
                          ? '#FFFFFF'
                          : theme.palette.grey[600],
                      marginRight: '8px',
                      background:
                        type === 'partner'
                          ? theme.palette.primary.main
                          : theme.palette.grey[500],
                    }}
                  >
                    Партнеры
                  </Button>
                  {!(chatType === 'tender' && filter === 'outgoing') ? (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setType('advert');
                      }}
                      sx={{
                        color:
                          type === 'advert'
                            ? '#FFFFFF'
                            : theme.palette.grey[600],
                        backgroundColor:
                          type === 'advert'
                            ? theme.palette.primary.main
                            : theme.palette.grey[500],
                      }}
                    >
                      Заявки
                    </Button>
                  ) : null}
                </Box>
              ) : chatType === 'advert' ? (
                <Button
                  variant="contained"
                  onClick={() => {
                    setType('partner');
                  }}
                  sx={{
                    color:
                      type === 'partner' ? '#FFFFFF' : theme.palette.grey[600],
                    marginRight: '8px',
                    background:
                      type === 'partner'
                        ? theme.palette.primary.main
                        : theme.palette.grey[500],
                  }}
                >
                  Партнеры
                </Button>
              ) : (
                <div />
              )}
              <Button
                variant="contained"
                onClick={() => onHandleClose()}
                sx={{
                  background: 'primary',
                  color: 'primary',
                  height: 36.5,
                }}
              >
                <Close />
              </Button>
            </Box>
            {chatType === 'advert' ? <Partners /> : null}
            {chatType === 'tender' ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                {type === 'partner' ? (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '73vh',
                    }}
                  >
                    <Partners />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Leads />
                  </Box>
                )}
              </Box>
            ) : null}
            {chatType === 'ankets' && !isLoading ? (
              chats?.data?.catalogChat ? (
                <Box sx={{ width: '100%' }}>
                  <Chat
                    title="Чат по Анкете"
                    chat={chats?.data?.catalogChat}
                    socketMessages={anketsMessages}
                  />
                </Box>
              ) : (
                <Empty />
              )
            ) : null}
          </Box>
        ) : null}
      </Box>
      <Backdrop
        sx={{
          color: 'blue',
          backgroundColor: 'rgba(255, 255, 255, 0.7)',
          zIndex: 9999,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
