import React, { useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  List,
  ListItem,
  Divider,
  Box,
} from '@mui/material';
import FormQuestionModal from './DynamicForm';
import DynamicForm from './DynamicForm';

// Интерфейс для данных вопроса
interface QuestionData {
  question: string;
  isRequired: boolean;
  inputType: 'checkbox' | 'radio';
  answers: string[];
}

export const UI: React.FC = () => {
  // Открыть модалку для создания нового вопроса

  return (
    <Box>
      <DynamicForm />
    </Box>
  );
};
