import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { PageContext } from 'store/pagecontext';
import WebApi from 'services/index';

interface FormField {
  id: number;
  name: string;
  formPropType: 'text' | 'number' | 'select' | 'checkbox';
  options?: { label: string; additionalInfo?: string; showInfo?: boolean }[];
  isOptional?: boolean;
  data?: FormField[];
  isNew?: boolean;
  isModified?: boolean;
  isDeleted?: boolean;
}

const DynamicForm: React.FC = () => {
  const { form } = useContext(PageContext);
  const [fields, setFields] = useState<FormField[]>(
    form.data?.formTemplateProps ?? []
  );
  const [initialFields, setInitialFields] = useState<FormField[]>(
    form.data?.formTemplateProps ?? []
  );
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    if (form.data?.formTemplateProps) {
      setInitialFields(form.data.formTemplateProps);
      setFields(form.data.formTemplateProps);
    }
  }, [form.data?.formTemplateProps]);

  useEffect(() => {
    setIsModified(JSON.stringify(fields) !== JSON.stringify(initialFields));
  }, [fields, initialFields]);

  const collectFields = (
    fieldsList: FormField[],
    criteria: (field: FormField) => boolean
  ): FormField[] => {
    return fieldsList.filter(criteria);
  };

  const handleSave = async () => {
    console.log(fields);
    try {
      const newFields = collectFields(
        fields,
        (field) => (field.isNew as boolean) && (!field.isDeleted as boolean)
      );
      const modifiedFields = fields.filter((item) => item.isModified);
      const deletedFields = collectFields(
        fields,
        (field) => field.isDeleted as boolean
      );

      const labelRequests = newFields.map(async (field) => {
        const response = await WebApi().Form.postFormTemplatesPost({
          formTemplateId: form.data.id,
          name: field.name,
          formPropType: field.formPropType,
          required: !field.isOptional,
          multiple: false,
          data: field.data?.map((subField) => ({
            name: subField.name,
            formPropType: subField.formPropType,
            options: subField.options,
            required: !subField.isOptional,
            multiple: false,
          })),
        });
        return { ...field, id: response.id };
      });

      await Promise.all(labelRequests);

      const updateRequests = modifiedFields.map((field) =>
        WebApi().Form.updateFormTemplatesPost({
          id: field.id,
          formTemplateId: form.data.id,
          name: field.name,
          formPropType: field.formPropType,
          required: !field.isOptional,
          multiple: false,
          data: field.data
            ?.filter((item) => !item.isDeleted)
            .map((subField) => ({
              id: subField.id,
              name: subField.name,
              options: subField.options,
              formPropType: subField.formPropType,
              required: !subField.isOptional,
              multiple: false,
            })),
        })
      );

      await Promise.all(updateRequests);

      const deleteRequests = deletedFields.map((field) =>
        WebApi().Form.removeFormTemplatesPost({ id: field.id })
      );

      await Promise.all(deleteRequests);

      const filterDeletedFields = (fieldsList: FormField[]): FormField[] =>
        fieldsList
          .filter((field) => !field.isDeleted)
          .map((field) => ({
            ...field,
            data: field.data ? filterDeletedFields(field.data) : [],
            isNew: false,
            isModified: false,
            isDeleted: false,
          }));

      const finalFields = filterDeletedFields(fields);
      setFields(finalFields);
      setInitialFields(finalFields);
      setIsModified(false);
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };
  const addField = (parentId: number | null = null) => {
    const newField: FormField = {
      id: Date.now(),
      name: '',
      formPropType: 'text',
      isOptional: false,
      data: [],
      isNew: true,
    };
    console.log(newField, 'newField');
    setFields((prevFields) =>
      parentId === null
        ? [...prevFields, newField] // Добавляем новый заголовок
        : prevFields.map((field) =>
            field.id === parentId
              ? {
                  ...field,
                  data: [...(field.data || []), { ...newField, isNew: false }],
                  isModified: true,
                }
              : field
          )
    );
  };

  const removeField = (id: number) => {
    const markAsDeleted = (
      fieldsList: FormField[],
      parentModified = false
    ): FormField[] =>
      fieldsList.map((field) => {
        // Если текущий элемент — это удаляемый элемент
        if (field.id === id) {
          return {
            ...field,
            isDeleted: true,
            isModified: true,
          };
        }

        // Обрабатываем дочерние элементы и помечаем родителя как измененный, если есть удаленные дочерние элементы
        const updatedData = field.data
          ? markAsDeleted(field.data, true)
          : field.data;
        const hasDeletedChild =
          updatedData && updatedData.some((child) => child.isDeleted);

        return {
          ...field,
          isModified: hasDeletedChild || field.isModified || parentModified,
          data: updatedData,
        };
      });

    setFields(markAsDeleted(fields));
  };

  const updateField = (id: number, key: keyof FormField, value: any) => {
    const updateFields = (
      fieldsList: FormField[],
      parentModified = false
    ): FormField[] =>
      fieldsList.map((field) => {
        if (field.id === id) {
          // Если обновляется текущий элемент
          return {
            ...field,
            [key]: value,
            isModified: true,
          };
        }

        // Обрабатываем дочерние элементы и помечаем родителя как измененного, если изменился хотя бы один дочерний элемент
        const updatedData = field.data
          ? updateFields(field.data, true)
          : field.data;
        const hasModifiedChild =
          updatedData && updatedData.some((child) => child.isModified);

        return {
          ...field,
          isModified: hasModifiedChild || field.isModified || parentModified,
          data: updatedData,
        };
      });

    setFields(updateFields(fields));
  };

  const toggleAdditionalInfo = (fieldId: number, optionIdx: number) => {
    setFields((prevFields) =>
      prevFields.map((field) => {
        if (
          field.data &&
          field.data.some((subField) => subField.id === fieldId)
        ) {
          const updatedSubFields = field.data.map((subField) =>
            subField.id === fieldId
              ? {
                  ...subField,
                  options: (subField.options || []).map((option, idx) =>
                    idx === optionIdx
                      ? { ...option, showInfo: !option.showInfo }
                      : option
                  ),
                  isModified: true,
                }
              : subField
          );

          return { ...field, data: updatedSubFields, isModified: true };
        }

        return field;
      })
    );
  };

  const renderField = (
    field: FormField,
    parentId: number | null = null,
    index: number = 0,
    parentNumber: string = '',
    level: number = 1
  ) => {
    if (field.isDeleted) return null;
    const fieldNumber = parentNumber
      ? `${parentNumber}.${index + 1}`
      : `${index + 1}`;
    return (
      <Box
        key={field.id}
        sx={{ marginBottom: '10px', fontSize: '12px !important' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography color="textSecondary">{fieldNumber}</Typography>
          <TextField
            value={field.name}
            onChange={(e) => updateField(field.id, 'name', e.target.value)}
            size="small"
            sx={{ flexGrow: 1 }}
          />
          {level > 1 && (
            <>
              <Select
                value={field.formPropType}
                onChange={(e) =>
                  updateField(field.id, 'formPropType', e.target.value)
                }
                size="small"
                sx={{ width: 120 }}
              >
                <MenuItem value="text">Текстовое поле</MenuItem>
                <MenuItem value="number">Числовое поле</MenuItem>
                <MenuItem value="select">Выбор одного из вариантов</MenuItem>
                <MenuItem value="checkbox">Чекбокс</MenuItem>
              </Select>
              <Checkbox
                checked={field.isOptional || false}
                onChange={(e) =>
                  updateField(field.id, 'isOptional', e.target.checked)
                }
              />
            </>
          )}
          {level === 1 && (
            <IconButton onClick={() => addField(field.id)} color="primary">
              <Add />
            </IconButton>
          )}
          <IconButton onClick={() => removeField(field.id)} color="error">
            <Delete />
          </IconButton>
        </Box>
        {field.formPropType === 'select' && level > 1 && (
          <Box sx={{ marginLeft: '20px', marginTop: '5px' }}>
            <Typography color="textSecondary">Варианты для выбора:</Typography>
            {field.options?.map((option, idx) => (
              <Box
                key={idx}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '15px',
                }}
              >
                <TextField
                  label={`Вариант ${idx + 1}`}
                  value={option.label}
                  onChange={(e) => {
                    const updatedOptions = [...(field.options || [])];
                    updatedOptions[idx] = {
                      ...updatedOptions[idx],
                      label: e.target.value,
                    };
                    updateField(field.id, 'options', updatedOptions);
                  }}
                  size="small"
                  sx={{ flexGrow: 1 }}
                />
                <Checkbox
                  checked={option.showInfo || false}
                  onChange={() => toggleAdditionalInfo(field.id, idx)}
                />
                {option.showInfo && (
                  <TextField
                    label="Доп. информация"
                    value={option.additionalInfo || ''}
                    onChange={(e) => {
                      const updatedOptions = [...(field.options || [])];
                      updatedOptions[idx] = {
                        ...updatedOptions[idx],
                        additionalInfo: e.target.value,
                      };
                      updateField(field.id, 'options', updatedOptions);
                    }}
                    size="small"
                    sx={{ marginLeft: '10px', flexGrow: 1 }}
                  />
                )}
                <IconButton
                  onClick={() => {
                    const updatedOptions = (field.options || []).filter(
                      (_, optionIdx) => optionIdx !== idx
                    );
                    updateField(field.id, 'options', updatedOptions);
                  }}
                  color="error"
                  size="small"
                >
                  <Delete />
                </IconButton>
              </Box>
            ))}
            <Button
              onClick={() =>
                updateField(field.id, 'options', [
                  ...(field.options || []),
                  { label: '' },
                ])
              }
              size="small"
              sx={{ marginTop: '12px' }}
            >
              Добавить вариант
            </Button>
          </Box>
        )}
        {field.data && (
          <Box sx={{ marginTop: '12px' }}>
            {field.data.map((subField, subIndex) =>
              renderField(subField, field.id, subIndex, fieldNumber, level + 1)
            )}
          </Box>
        )}
        <Divider sx={{ marginY: 1 }} />
      </Box>
    );
  };

  return (
    <Box sx={{ padding: '20px', maxWidth: 800, margin: '0 auto' }}>
      <Typography variant="h5" gutterBottom>
        Анкета
      </Typography>
      <Box
        sx={{
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button variant="contained" color="primary" onClick={() => addField()}>
          Добавить заголовок
        </Button>
        {isModified && (
          <Button variant="contained" color="primary" onClick={handleSave}>
            Сохранить
          </Button>
        )}
      </Box>
      <Box flexDirection="column">
        {fields.map((field, index) => renderField(field, null, index))}
      </Box>
    </Box>
  );
};

export default DynamicForm;
